<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      width="360"
      min-height="370"
      :class="professional.item_id ? 'mt-6 mx-auto' : 'mx-auto my-2'"
      :elevation="hover ? 6 : 2"
      :ripple="false"
    >
      <v-toolbar
        v-if="professional && (professional.item_id && !professional.declined || (professional.item_id && professional.declined))"
        flat
        :color="professional.declined ? 'error' : 'accent'"
        class="mb-3"
      >
        <v-icon
          v-if="professional.declined"
          class="mr-3"
          color="white"
        >
          mdi-alert-octagon
        </v-icon>
        <v-icon
          v-if="!professional.declined"
          class="mb-1 mr-2"
          color="white"
        >
          mdi-account-clock-outline
        </v-icon>
        <span
          v-if="!professional.declined"
          style="color: white;"
        >
          {{ $t('common|pending_invitation') }}...
        </span>
        <span
          v-if="professional.declined"
          style="color: white;"
        >
          {{ $t('common|invitation_declined') }}
        </span>
        <v-spacer />
        <v-btn
          v-if="!professional.declined"
          rounded
          x-small
          outlined
          color="primary"
          @click="cancelInvitationClientAccount(professional)"
        >
          cancel invitation
        </v-btn>
        <v-btn
          v-if="professional.declined"
          icon
          right
          @click.stop="deleteDeclinedInvitation(professional)"
        >
          <v-icon>
            {{ icons.trash }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <div
        :class="professional.item_id && !professional.declined || (professional.item_id && professional.declined) ? 'disabled-card' : ''"
      >
        <v-card-title
          v-if="professional && professional.accountData"
        >
          <v-avatar
            size="40"
            color="primary"
            class="mr-2 my-2"
          >
            <img
              v-if="professional && professional.avatar"
              :src="`${cfg.baseURL}downloadAvatar/${professional._id}/${professional.avatar.raw.filename}`"
            >
            <v-icon
              v-if="!professional.avatar"
              color="white"
              class="pa-1"
            >
              {{ icons.tie }}
            </v-icon>
          </v-avatar>
          {{ professional.accountData.firstName ? professional.accountData.firstName : professional.accountData.accountName }} {{ professional.accountData.lastName ? professional.accountData.lastName : '' }}
        </v-card-title>

        <v-card-title
          v-if="professional && professional.companyData"
        >
          <v-avatar
            size="40"
            color="primary"
            class="mr-2 my-2"
          >
            <img
              v-if="professional && professional.avatar"
              :src="`${cfg.baseURL}downloadAvatar/${professional._id}/${professional.avatar.raw.filename}`"
            >
            <v-icon
              v-if="!professional.avatar"
              color="white"
              class="pa-1"
            >
              {{ icons.tie }}
            </v-icon>
          </v-avatar>
          {{ professional.companyData.companyName }}
        </v-card-title>

        <v-card-title
          v-if="professional && professional.groupName"
        >
          <v-avatar
            size="40"
            color="primary"
            class="mr-2 my-2"
          >
            <img
              v-if="professional && professional.avatar"
              :src="`${cfg.baseURL}downloadAvatar/${professional._id}/${professional.avatar.raw.filename}`"
            >
            <v-icon
              v-if="!professional.avatar"
              color="white"
              class="pa-1"
            >
              {{ icons.tie }}
            </v-icon>
          </v-avatar>
          {{ professional.groupName }}
        </v-card-title>

        <v-card-text
          class="mt-n4"
          style="min-height: 50px"
        >
          <div
            v-if="professional && professional.accountData"
          >
            <div>
              {{ `${ capitalizeFirstLetter($t('common|type')) }: ${removeUndefined(professional.accountType)}` }}
            </div>
            <div>
              {{ `${ $t('profile|phone') }: ${removeUndefined(professional.accountData.phone)}` }}
            </div>
            <div>
              {{ `${ capitalizeFirstLetter($t('common|email')) }: ${removeUndefined(professional.accountData.email)}` }}
            </div>
            <div>
              {{ `${ $t('clients|address') }: ${removeUndefined(professional.accountData.address)}` }}
            </div>
            <div>
              {{ `${ $t('clients|city') }: ${removeUndefined(professional.accountData.city)}` }}
            </div>
          </div>

          <div
            v-if="professional.companyData"
          >
            <div>
              {{ `${ $t('common|name') }: ${professional.companyData.companyName}` }}
            </div>
            <div>
              {{ `${ $t('profile|phone') }: ${removeUndefined(professional.companyData.phone)}` }}
            </div>
            <div>
              {{ `${ capitalizeFirstLetter($t('common|email')) }: ${removeUndefined(professional.companyData.email)}` }}
            </div>
            <div>
              {{ `${ $t('clients|address') }: ${removeUndefined(professional.companyData.address)}` }}
            </div>
            <div>
              {{ `${ $t('clients|city') }: ${removeUndefined(professional.companyData.city)}` }}
            </div>
            <div>
              {{ `${ $t('clients|postal_code') }: ${removeUndefined(professional.companyData.postal_code)}` }}
            </div>
            <div>
              {{ `${ $t('mixed|representative_name') }: ${removeUndefined(professional.companyData.representativeName)}` }}
            </div>
            <div>
              {{ `${ $t('mixed|representative_email') }: ${removeUndefined(professional.companyData.representativeEmail)}` }}
            </div>
          </div>

          <div
            v-if="professional && professional.groupName"
          >
            <div>
              {{ `${ $t('common|name') }: ${professional.groupName}` }}
            </div>
            <div>
              {{ `${ $t('profile|phone') }: ${removeUndefined(professional.belongsToCompany.companyData.phone)}` }}
            </div>
            <div>
              {{ `${ capitalizeFirstLetter($t('common|email')) }: ${removeUndefined(professional.groupEmail)}` }}
            </div>
            <div>
              {{ `${ $t('clients|address') }: ${removeUndefined(professional.belongsToCompany.companyData.address)}` }}
            </div>
            <div>
              {{ `${ $t('clients|city') }: ${removeUndefined(professional.belongsToCompany.companyData.city)}` }}
            </div>
            <div>
              {{ `${ $t('clients|postal_code') }: ${removeUndefined(professional.belongsToCompany.companyData.postal_code)}` }}
            </div>
            <div>
              {{ `${ $t('mixed|representative_name') }: ${removeUndefined(professional.belongsToCompany.companyData.representativeName)}` }}
            </div>
            <div>
              {{ `${ $t('mixed|representative_email') }: ${removeUndefined(professional.belongsToCompany.companyData.representativeEmail)}` }}
            </div>
          </div>

          <div
            v-if="professional && professional.item_id"
          >
            <div v-if="professional.item_id.groupName">
              {{ `${ $t('common|name') }: ${professional.item_id.groupName}` }}
            </div>
            <div v-if="professional.item_id.companyData">
              {{ `${ $t('common|name') }: ${professional.item_id.companyData.companyName}` }}
            </div>
            <div v-if="professional.item_id.accountData">
              {{ `${ $t('common|name') }: ${professional.item_id.accountData.firstName && professional.item_id.accountData.lastName ? professional.item_id.accountData.firstName + ' ' + professional.item_id.accountData.lastName : 'not available'}` }}
            </div>
            <div v-if="professional.item_id.groupEmail">
              {{ `${ capitalizeFirstLetter($t('common|email')) }: ${professional.item_id.groupEmail}` }}
            </div>
            <div v-if="professional.item_id.companyData">
              {{ `${ capitalizeFirstLetter($t('common|email')) }: ${professional.item_id.companyData.email}` }}
            </div>
            <div v-if="professional.item_id.accountData">
              {{ `${ capitalizeFirstLetter($t('common|email')) }: ${professional.item_id.email}` }}
            </div>
          </div>

          <div
            v-if="!professional.item_id"
            style="height: 65px; width: 300px; position: relative; bottom: 0px; top: 0px; overflow: auto;"
          >
            <span>
              {{ $t('cases|cases') }}:
            </span>
            <v-chip
              v-for="c in myCasesSharedWithProfessional"
              :key="c._id"
              link
              :to="`/cases/${c._id}`"
              class="ma-1"
              small
              color="primary"
              outlined
              @click.stop
            >
              <v-icon
                left
                small
              >
                mdi-briefcase-edit
              </v-icon>
              {{ c.caseName }}
            </v-chip>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="!professional.item_id"
        >
          <v-btn
            color="primary"
            outlined
            rounded
            small
            absolute
            bottom
            left
            class="ml-n2"
            link
            :to="`/professional/${professional._id}`"
            @click.stop
          >
            {{ $t('actions|open') }}
            <v-icon
              small
              class="ml-1"
            >
              mdi-arrow-top-right
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            text
            icon
            absolute
            bottom
            right
            color="primary"
            @click.stop="deleteThisProfessional(professional)"
          >
            <v-icon>
              {{ icons.trash }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiTrashCanOutline, mdiAccountTie } from '@mdi/js'
import cfg from '@/config'

export default {
  props: {
    professional: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        trash: mdiTrashCanOutline,
        tie: mdiAccountTie
      },
      cfg
    }
  },
  computed: {
    ...mapState({
      cases: state => state.cases.cases,
      account: state => state.account.account
    }),
    myCasesSharedWithProfessional () {
      let sharedCases = []
      this.cases.forEach(c => {
        const index = c.sharedWith.accounts.findIndex(acc => acc._id === this.professional._id)
        if (index !== -1) {
          sharedCases.push(c)
        }
      })
      return sharedCases
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      deleteProfessional: 'account/deleteProfessional',
      deleteTheDeclinedInvitation: 'account/deleteTheDeclinedInvitation',
      cancelInvitationFromClientAccount: 'account/cancelInvitationFromClientAccount'
    }),
    capitalizeFirstLetter (text) {
      text = text.charAt(0).toUpperCase() + text.slice(1)
      return text
    },
    removeUndefined (item) {
      if (typeof item === 'undefined') return this.$t('common|not_available')
      else return item
    },
    async deleteThisProfessional (professional) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource')
      })
      if (!res) return
      if (res) {
        const _id = professional._id
        let payload
        if (professional.accountData) {
          payload = {
            deleteIdFromAccountsArr: true
          }
        } else if (professional.companyData) {
          payload = {
            deleteIdFromCompanyArr: true
          }
        } else if (professional.groupName) {
          payload = {
            deleteIdFromGroupsArr: true
          }
        }
        try {
          await this.deleteProfessional({ _id: _id, payload })
          this.addToast({
            title: this.$t('message|delete_professional_success'),
            snackbarColor: 'success',
            color: 'white'
          })
        } catch (error) {
          console.error(error, 'error')
          this.addToast({
            title: this.$t('message|delete_professional_fail'),
            snackbarColor: 'error',
            color: 'white'
          })
        }
      }
    },
    async deleteDeclinedInvitation (pro) {
      const _id = this.account._id
      const payload = {
        professionalId: pro.item_id._id,
        deleteProfessional: true
      }
      try {
        await this.deleteTheDeclinedInvitation({ _id, payload })
        this.addToast({
          title: this.$t('message|delete_declined_invitation_success'),
          snackbarColor: 'success',
          color: 'white'
        })
      } catch (error) {
        console.error('error', error)
      }
    },
    async cancelInvitationClientAccount (professional) {
      const _id = this.account._id
      let mail = null
      if (professional.onPending && professional.onPending === 'Account') mail = professional.item_id.email
      if (professional.onPending && professional.onPending === 'Company') mail = professional.item_id.companyData.email
      if (professional.onPending && professional.onPending === 'Group') mail = professional.item_id.groupEmail
      const invitationSent = this.account.invitationsSent.find(inv => inv.recipientEmail === mail)
      const res = await this.$dialog.confirm({
        text: this.$t('warning|you_want_to_cancel_invitation'),
        title: this.$t('common|warning')
      })
      if (!res) return
      const payload = {
        invitationSent: invitationSent,
        professional: professional
      }
      try {
        await this.cancelInvitationFromClientAccount({ _id, payload })
      } catch (error) {
        console.error(error)
        this.addToast({
          title: 'Canceling the invitation was not successful',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
  .disabled-card {
    pointer-events: none;
    opacity: 0.5;
  }
  .cancel-btn {
    pointer-events: auto;
    opacity: 1;
  }
</style>
